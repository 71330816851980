import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    Grid,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Box,
    LinearProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CustomAlert from "./CustomAlert";
import DetailBaseInfo from "./DetailBaseInfo";
import DetailChildrenInfo from "./DetailChildrenInfo";
import DetailDocInfo from "./DetailDocInfo";
import DetailPersonalInfo from "./DetailPersonalInfo";
import DetailRHInfo from "./DetailRHInfo";
import { Colaborador } from "../app/Colaborador";
import { DataContext } from "../app/DataContext";
import StepperComponent from "./StepperComponent";
import { Home } from "@mui/icons-material";
import { useHistory } from 'react-router-dom'
import { PattrolUserContext } from "pattrol-frontend-components";
import DetailPhotoInfo from "./DetailPhotoInfo";


/* ************************************* */
/* define estilos                        */
/* ************************************* */
const useStyles = makeStyles({
    inputsContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "30px 10px 30px 10px",
    },
    inputsContainer21: {
        fontSize: "40px",
        fontStyle: "initial",
        fontFamily: "Helvetica",
        margin: "10% auto",
        textAlign: "center",
        color: "rgb(0, 48, 7)",
    },
    switchStyle: {
        marginLeft: "83%",
    },
    saveStyle: {
        width: "50%",
        float: "left",
    },
    legendaEdit: {
        fontSize: "10px",
        fontStyle: "initial",
        fontFamily: "Helvetica",
        textAlign: "right",
        color: "rgb(179, 179, 179)",
    },
    divSwitch: {
        float: "center",
    },
    dinMenuInferior: {
        width: "100%",
    },
    link: {
        textDecoration: "none",
    },
    select: {
        color: "black",
    },
    toggles: {
        display: "flex",
        width: "100%",
    },
    divSwitchVale: {
        width: "25%",
        float: "center",
    },
});


/* ************************************* */
/* componente Details                    */
/* ************************************* */
const Details = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const [isNew, setIsNew] = useState(true);
    const [editavel, setEditavel] = useState(false);
    const [promptDelete, setPromptDelete] = useState(false);
    const history = useHistory();
    const { permissions } = useContext(PattrolUserContext)
    const permissionsEdit = permissions ? permissions.some((perm) => [1, 2].includes(perm)) : false; // permisaao para editar

    const [colaborador, setColaborador] = useState({
        ...new Colaborador(),
    });
    const [open, setOpen] = useState(false);

    const [messageStatus, setMessageStatus] = useState({
        success: false,
        message: "Update ainda não enviado",
    });
    const codColaborador = props.location.data;
    const dataContext = useContext(DataContext);


    /* modifica conteudo toda vez que mudar o CodColaborador */
    useEffect(() => {
        setIsLoading(true);

        // se houver codColaborador (ou seja, edicao), busca cadastro existente
        // do contrario (inclusao), inicia cadastro "vazio"
        if (codColaborador) {
            // cadastro existente
            // busca dados na API
            dataContext.getApiColaborador(codColaborador).then((response) => {
                setColaborador({ ...new Colaborador({ ...response }) });
                setIsNew(false);
                setEditavel(false);
            });
        } else {
            // inclusao de novo cadastro
            // nao eh necessario criar uma nova varivel vazia, ja foi feito acima
            setIsNew(true);
            setEditavel(true);
        }

        setIsLoading(false);
    }, [codColaborador, dataContext]);

    // cria uma rotina para atualizar os dados do colaborador a partir de mudancas nos campos da tela
    const handleChangeText = (e) => {
        setColaborador({
            ...colaborador, [e.target.id]: e.target.value
        });
    };

    // validateData: valida os dados do colaborador
    const validateData = () => {
        let successful = true;
        if (!colaborador.NomeColaborador) {
            successful = false;
            setMessageStatus({
                success: false,
                message: "O nome do colaborador não pode ficar vazio!",
            });
        }
        else if (!colaborador.Status) {
            successful = false;
            setMessageStatus({
                success: false,
                message: "O status do colaborador não pode ficar vazio!",
            });
        }
        else if (colaborador.Status.code === 2 && colaborador.DataDemissao === null) {
            successful = false;
            setMessageStatus({
                success: false,
                message: "Data de demissão não pode ficar vazia quando o status estiver desligado !",
            });
        }

        return successful;
    };

    // SaveData: salva dados do colaborador (seja novo, seja existente)
    const saveData = () => {
        // apresenta indicador de espera
        setIsLoading(true);

        // valida o preenchimento
        if (validateData()) {
            // preenchimento ok; salva dados do colaborador
            // antes de enviar, verifica se precisara atualizar alguma lista
            let newNaturalidade = colaborador.Naturalidade ? (colaborador.Naturalidade.code ? false : true) : false;
            let newNacionalidade = colaborador.Nacionalidade ? (colaborador.Nacionalidade.code ? false : true) : false;
            let newCidade = colaborador.Cidade ? (colaborador.Cidade.code ? false : true) : false;

            // verifica se esta criando um novo, ou atualizando um existente
            if (!colaborador.CodColaborador) {
                // criando novo colaborador
                dataContext.insertApiColaborador(colaborador).then((response) => {
                    if (response) {
                        // sucesso
                        // verifica se deve atualizar alguma lista
                        if (newNaturalidade || newCidade) {
                            dataContext.tabCidadeReload();
                        }
                        if (newNacionalidade) {
                            dataContext.tabPaisReload();
                        }

                        // TODO: verificar o codigo de retorno
                        setMessageStatus({
                            success: true,
                            message: "Colaborador cadastrado com sucesso",
                        });
                        setEditavel(false);
                        setTimeout(() => {
                            history.push('/hr/colaborador');
                        }, 2000);
                    } else {
                        // algum problema
                        // TODO: diagnosticar erro
                        setMessageStatus({
                            success: false,
                            message: "Erro não identificado",
                        });
                    }
                    setIsLoading(false);
                    setOpen(true);
                })
            } else {
                // atualizando colaborador existente
                dataContext.updateApiColaborador(colaborador).then((response) => {
                    if (response) {
                        // sucesso
                        // verifica se deve atualizar alguma lista
                        if (newNaturalidade || newCidade) {
                            dataContext.tabCidadeReload();
                        }
                        if (newNacionalidade) {
                            dataContext.tabPaisReload();
                        }

                        // TODO: verificar o codigo de retorno
                        setMessageStatus({
                            success: true,
                            message: "Colaborador atualizado com sucesso",
                        });
                        setEditavel(false);
                        setTimeout(() => {
                            history.push('/hr/colaborador');
                        }, 2000);
                    } else {
                        // algum problema
                        // TODO: diagnosticar erro
                        setMessageStatus({
                            success: false,
                            message: "Erro não identificado",
                        });
                    }
                    setIsLoading(false);
                    setOpen(true);
                })
            }
        } else {
            // erro de prrenchimento de dados

            setOpen(true);
        }

        setIsLoading(false);
    };

    // exclui colaborador do cadastro
    const deleteColaborador = () => {
        setIsLoading(true);
        dataContext.deleteApiColaborador(colaborador.CodColaborador).then((response) => {
            if (response) {
                props.history.push("/hr/colaborador");
            } else {
                setMessageStatus({
                    success: false,
                    message:
                        "Erro: o funcionário não pode ser deletado pois existem registros associados a ele.",
                });
            }
            setIsLoading(false);
            setOpen(true);
            setPromptDelete(false);
        });
    };

    // atualiza o objeto 'colaborador' que esta na memoria com a data de nascimento dos filhos
    const updateFilhos = (newFilhos) => {
        setColaborador({
            ...colaborador, DataNascimentoFilhos: newFilhos
        });
    };

    // imprime a ficha de registro deste colaborador, em PDF
    const imprimeFichaRegistro = () => {
        setIsLoading(true);

        dataContext.getApiFichaRegistro(colaborador.CodColaborador).then((response) => {
            let url = window.URL.createObjectURL(response);
            window.open(url);
        });

        setIsLoading(false);
    }

    /* ************************************* */
    /* monta o componente                    */
    /* ************************************* */
    function renderStepContent(step) {
        switch (step) {
            case 0:
                return <DetailBaseInfo
                    colaborador={colaborador}
                    handleChangeText={handleChangeText}
                    setColaborador={setColaborador}
                    editavel={editavel}
                />;
            case 1:
                return <div>
                    <DetailPersonalInfo
                        colaborador={colaborador}
                        handleChange={handleChangeText}
                        setColaborador={setColaborador}
                        editavel={editavel}
                    />
                    <DetailChildrenInfo
                        filhos={colaborador.DataNascimentoFilhos ? colaborador.DataNascimentoFilhos : []}
                        handleFilhos={updateFilhos}
                        editavel={editavel}
                    />
                </div>;
            case 2:
                return <DetailRHInfo
                    colaborador={colaborador}
                    handleChange={handleChangeText}
                    setColaborador={setColaborador}
                    editavel={editavel}
                />;
            default:
                return <div>
                    <DetailDocInfo
                        colaborador={colaborador}
                        handleChange={handleChangeText}
                        setColaborador={setColaborador}
                        editavel={editavel}
                    />
                    <DetailPhotoInfo
                        colaborador={colaborador}
                        setColaborador={setColaborador}
                        isNew={isNew}
                        editavel={editavel} />
                </div>
        }
    }
    return (
        <Container

        >
            <Grid container display='flex' direction="row" alignItems="center" pt={10}>
                <Grid item md={2}>
                    <Link to="/hr/colaborador" className={classes.link}>
                        <IconButton className={classes.iconButton}>
                            <Home />
                        </IconButton>
                    </Link>
                </Grid>
                {permissionsEdit ? (
                    <Grid item md={8}>
                        <h2 style={{ margin: "1% auto", marginBottom: "4%", textAlign: "center", color: "rgb(0, 48, 7)", }}>
                            {isNew ? "Incluir" : "Editar"} Colaborador
                        </h2>
                    </Grid>)
                    : (
                        <Grid item md={8}>
                            <h2 style={{ margin: "1% auto", marginBottom: "4%", textAlign: "center", color: "rgb(0, 48, 7)", }}>
                                Detalhes do Colaborador
                            </h2>
                        </Grid>
                    )
                }
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        "& .MuiTextField-root": {
                            width: "100%",
                            margin: "10px auto",
                            borderRadius: "5px",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                        },
                    }}
                >
                    <StepperComponent
                        renderStepContent={renderStepContent}
                        isNew={isNew}
                        editavel={editavel}
                        setEditavel={setEditavel}
                        saveData={saveData}
                        setPromptDelete={setPromptDelete}
                        imprimeFichaRegistro={imprimeFichaRegistro}
                        permissionsEdit={permissionsEdit} />

                </Box>
            </Grid>
            {isLoading && <LinearProgress />}
            <CustomAlert
                open={open}
                severity={
                    messageStatus.success && messageStatus.success === true
                        ? "success"
                        : "error"
                }
                onClose={() => setOpen(false)}
            >
                {messageStatus.message}
            </CustomAlert>
            <Dialog
                open={promptDelete}
                onClose={() => setPromptDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deseja deletar o usuário?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setPromptDelete(false)}>Não</Button>
                    <Button onClick={() => deleteColaborador()} autoFocus>Sim</Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
};
export default Details;