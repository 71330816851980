import React, { useContext } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { valueOrEmptyString, listWithEmptyCodeAndLabel } from "../app/Tools";
import { DataContext } from "../app/DataContext";

const DetailPersonalInfo = ({
    colaborador,
    handleChange,
    setColaborador,
    editavel,
}) => {
    const filterCidade = createFilterOptions({ stringify: (option) => option.label });
    const dataContext = useContext(DataContext);

    return (
        <div
            style={{
                backgroundColor: "#EDEDED",
                border: 0,
                borderRadius: 9,
                padding: 10,
                margin: 5,
            }}
        >
            <div
                style={{
                    backgroundColor: "white",
                    border: 0,
                    borderRadius: 9,
                    padding: 10,
                }}
            >
                <Grid container spacing={2} rowSpacing={2}>
                    {/* Nome pai, nome mae */}
                    <Grid item md={6}>
                        <TextField
                            id="NomePai"
                            label="Nome Pai"
                            value={valueOrEmptyString(colaborador.NomePai)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="NomeMae"
                            label="Nome Mãe"
                            value={valueOrEmptyString(colaborador.NomeMae)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>

                    {/* endereco, num, complemento */}
                    <Grid item md={5}>
                        <TextField
                            id="Endereco"
                            label="Endereço"
                            value={valueOrEmptyString(colaborador.Endereco)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="Numero"
                            label="Número"
                            value={valueOrEmptyString(colaborador.Numero)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id="Complemento"
                            label="Complemento"
                            value={valueOrEmptyString(colaborador.Complemento)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>

                    {/* bairro, cidade, UF, cep */}
                    <Grid item md={4}>
                        <TextField
                            id="Bairro"
                            label="Bairro"
                            value={valueOrEmptyString(colaborador.Bairro)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            value={colaborador.Cidade}
                            onChange={(event, newValue) => {
                                let newValueObj = { code: null, label: "" };
                                if (typeof newValue === 'string') {
                                    newValueObj.label = newValue;
                                } else if (!newValue) {
                                    // do nothing
                                } else if (newValue.inputValue) {
                                    // Create a new value from the user input
                                    newValueObj.label = newValue.inputValue;
                                } else {
                                    newValueObj.code = newValue.code;
                                    newValueObj.label = newValue.label;
                                }
                                setColaborador({
                                    ...colaborador, Cidade: newValueObj
                                });
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filterCidade(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.label);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        label: `Adicionar "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="Cidade"
                            disabled={!editavel}
                            options={listWithEmptyCodeAndLabel(dataContext.tabCidade)}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            // sx={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Cidade" InputLabelProps={{ shrink: true }} />
                            )}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <Autocomplete
                            id="Estado"
                            value={colaborador.Estado}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Estado: newValue
                                });
                            }}
                            disabled={!editavel}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabUF)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Estado"
                                    InputLabelProps={{ shrink: true }}
                                />}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <TextField
                            id="CEP"
                            label="CEP"
                            value={valueOrEmptyString(colaborador.CEP)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>

                    {/* telefone fixo, celular e outro */}
                    <Grid item md={4}>
                        <TextField
                            id="TelefoneFixo"
                            label="Telefone Fixo"
                            value={valueOrEmptyString(colaborador.TelefoneFixo)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id="TelefoneCelular"
                            label="Telefone Celular"
                            value={valueOrEmptyString(colaborador.TelefoneCelular)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            id="TelefoneAlternativo"
                            label="Telefone Alternativo"
                            value={valueOrEmptyString(colaborador.TelefoneAlternativo)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default DetailPersonalInfo;