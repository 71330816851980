import React, { useContext } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

import { valueOrEmptyString, valueOrEmptyDate, listWithEmptyCodeAndLabel } from "../app/Tools";
import { tipocnh } from "../app/TempData";

import { DataContext } from "../app/DataContext";

const DetailDocInfo = ({
    colaborador,
    handleChange,
    setColaborador,
    editavel,
}) => {
    const dataContext = useContext(DataContext);

    return (
        <div
            style={{
                backgroundColor: "#EDEDED",
                border: 0,
                borderRadius: 9,
                padding: 10,
                margin: 5,
            }}
        >

            <div
                style={{
                    backgroundColor: "white",
                    border: 0,
                    borderRadius: 9,
                    padding: 10,
                }}
            >

                <Grid container spacing={2} rowSpacing={2}>
                    {/* RG */}
                    <Grid item md={3}>
                        <TextField
                            id="RGNumero"
                            label="RG Número"
                            value={valueOrEmptyString(colaborador.RGNumero)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="RGOrgaoEmissor"
                            label="RG Emissor"
                            value={valueOrEmptyString(colaborador.RGOrgaoEmissor)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="RGDataEmissao"
                            label="RG Data Emissão"
                            type="date"
                            value={valueOrEmptyDate(colaborador.RGDataEmissao)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                    </Grid>

                    {/* CNH */}
                    <Grid item md={3}>
                        <TextField
                            id="CNHNumero"
                            label="CNH Número"
                            value={valueOrEmptyString(colaborador.CNHNumero)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            id="CNHTipo"
                            value={valueOrEmptyString(colaborador.CNHTipo)}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, CNHTipo: newValue
                                });
                            }}
                            options={tipocnh}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="CNH Tipo"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="CNHDataEmissao"
                            label="CNH Data Emissão"
                            type="date"
                            value={valueOrEmptyDate(colaborador.CNHDataEmissao)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="CNHDataVencimento"
                            label="CNH Data Vencimento"
                            type="date"
                            value={valueOrEmptyDate(colaborador.CNHDataVencimento)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>

                    {/* CTPS */}
                    <Grid item md={3}>
                        <TextField
                            id="CTPSNumero"
                            label="CTPS Número"
                            value={valueOrEmptyString(colaborador.CTPSNumero)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="CTPSSerie"
                            label="CTPS Série"
                            value={valueOrEmptyString(colaborador.CTPSSerie)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            id="CTPSUF"
                            value={colaborador.CTPSUF}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, CTPSUF: newValue
                                });
                            }}
                            disabled={!editavel}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabUF)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="CTPS UF"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="CTPSDataEmissao"
                            label="CTPS Data Emissão"
                            type="date"
                            value={valueOrEmptyDate(colaborador.CTPSDataEmissao)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>

                    {/* Titulo Eleitor */}
                    <Grid item md={3}>
                        <TextField
                            id="TituloENumero"
                            label="Título Eleitor Número"
                            value={valueOrEmptyString(colaborador.TituloENumero)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="TituloEZona"
                            label="Título Eleitor Zona"
                            value={valueOrEmptyString(colaborador.TituloEZona)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="TituloESecao"
                            label="Titulo Eleitor Seção"
                            value={valueOrEmptyString(colaborador.TituloESecao)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="TituloEDataEmissao"
                            label="Titulo Eleitor Data Emissão"
                            type="date"
                            value={valueOrEmptyDate(colaborador.TituloEDataEmissao)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>

                    {/* Certificado reservista, PIS-PASEP, eSocial */}
                    <Grid item md={3}>
                        <TextField
                            id="CertificadoReservista"
                            label="Certicado de Reservista"
                            value={valueOrEmptyString(colaborador.CertificadoReservista)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="PISPASEP"
                            label="PIS PASEP"
                            value={valueOrEmptyString(colaborador.PISPASEP)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="Esocial"
                            label="eSocial"
                            value={valueOrEmptyString(colaborador.Esocial)}
                            inputProps={{
                                group: "docColaborador",
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default DetailDocInfo;