import React from "react";
import {
    Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { valueOrFalse } from "../app/Tools";

const useStyles = makeStyles({
    switchStyle: {
        marginLeft: "72%",
    },
    legendaEdit: {
        fontSize: "10px",
        fontStyle: "initial",
        fontFamily: "Helvetica",
        textAlign: "right",
        marginLeft: "50%",
        color: "rgb(179, 179, 179)",
    },
    toggles: {
        display: "flex",
        width: "100%",
    },
    divSwitchVale: {
        width: "20%",
        float: "center",
        textAlign: "center",
    },
});

const DetailRHInfoVales = ({ colaborador, setColaborador, editavel }) => {
    const classes = useStyles();
    const updateToggle = (newValue, campo) => {  
        setColaborador({
        ...colaborador, [campo]: newValue 
        });
    };

    return (
        <div className={classes.toggles}>
            <div className={classes.divSwitchVale}>
                <Switch
                    className={classes.switchStyle}
                    checked={valueOrFalse(colaborador.ValeTransporte)}
                    onChange={(e) => {
                        updateToggle(e.target.checked, "ValeTransporte");
                    }}
                    disabled={!editavel}
                />
                <h1 className={classes.legendaEdit}>Vale Transporte</h1>
            </div>
            <div className={classes.divSwitchVale}>
                <Switch
                    className={classes.switchStyle}
                    checked={valueOrFalse(colaborador.ValeRefeicao)}
                    onChange={(e) => {
                        updateToggle(e.target.checked, "ValeRefeicao");
                    }}
                    disabled={!editavel}
                />
                <h1 className={classes.legendaEdit}>Vale Refeição</h1>
            </div>
            <div className={classes.divSwitchVale}>
                <Switch
                    className={classes.switchStyle}
                    checked={valueOrFalse(colaborador.ValeAlimentacao)}
                    onChange={(e) => {
                        updateToggle(e.target.checked, "ValeAlimentacao");
                    }}
                    disabled={!editavel}
                />
                <h1 className={classes.legendaEdit}>Vale Alimentação</h1>
            </div>
            <div className={classes.divSwitchVale}>
                <Switch
                    className={classes.switchStyle}
                    checked={valueOrFalse(colaborador.AjudaCusto)}
                    onChange={(e) => {
                        updateToggle(e.target.checked, "AjudaCusto");
                    }}
                    disabled={!editavel}
                />
                <h1 className={classes.legendaEdit}>Ajuda de Custo</h1>
            </div>
        </div>
    );
};

export default DetailRHInfoVales;
