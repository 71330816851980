import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DataContext } from "../app/DataContext";
import { Delete, Check } from '@mui/icons-material';

const Input = styled('input')({
    display: 'none'
});

const ImagePreview = styled('div')({
    position: 'relative',
    border: '1px solid black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10rem',
    height: '12rem',
    marginBottom: '1rem',
});

const DetailPhotoInfo = ({ colaborador, setColaborador, isNew, editavel }) => {
    const [imageData, setImageData] = useState(null);
    const dataContext = useContext(DataContext);
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const [botaoConfirmaClicado, setBotaoConfirmaClicado] = useState(false);
    const [botaoApagarClicado, setBotaoApagarClicado] = useState(false)
    const fotoColaboradorAtual = colaborador.FotoColaborador;
    const codColaborador = colaborador.CodColaborador;
    const handleImageChange = e => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageData(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleConfirmPhoto = async () => {
        const imageBase64 = { image: imageData };
        const uploadFileName = await dataContext.uploadFotoColaborador(imageBase64);
        setColaborador({
            ...colaborador,
            FotoColaborador: uploadFileName.fileName
        });
        setBotaoConfirmaClicado(true);
        if (fotoColaboradorAtual !== null && (fotoColaboradorAtual !== uploadFileName.fileName) && botaoApagarClicado === false) {
            await dataContext.deleteFotoColaborador(fotoColaboradorAtual)
        }
    };

    const removeFoto = () => {
        setImageData(null);
        setFileInputKey(Date.now());
        setBotaoConfirmaClicado(false);
        setBotaoApagarClicado(true)
    };

    useEffect(() => {
        async function fetchData() {
            if (!isNew && imageData === null && botaoApagarClicado === true) {
                await dataContext.deleteFotoColaborador(fotoColaboradorAtual);
                await dataContext.updateFotoColaboradorBanco(codColaborador);
            }
        }
        fetchData();
    });
    useEffect(() => {
        if (!isNew && fotoColaboradorAtual != null) {
            dataContext.getApiFotoColaborador(colaborador.FotoColaborador).then((result) => {
                setImageData(result.url);
            });
        }
    });
    return (
        <div style={{
            backgroundColor: "#EDEDED",
            border: 0,
            borderRadius: 9,
            padding: 10,
            margin: 5,
        }}>
            <div style={{
                backgroundColor: "white",
                border: 0,
                borderRadius: 9,
                padding: 10,
            }}>
                {editavel && (
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon style={{ fontSize: '18px' }} />}
                        style={{ marginBottom: '1rem', backgroundColor: 'green', width: '10rem', height: '2rem', padding: '2px' }}
                    >
                        <span style={{ fontSize: '12px' }}>Selecionar Foto </span>
                        <Input key={fileInputKey} accept="image/*" id="photo-upload" type="file" onChange={handleImageChange} />
                    </Button>
                )}
                <div style={{ position: 'relative' }}>
                    {imageData ? (
                        <>
                            <ImagePreview>
                                <img src={imageData} style={{ maxWidth: '100%', maxHeight: '100%', display: 'block' }} alt='foto' />
                                <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                                    {editavel && (
                                        <Button variant="contained"

                                            onClick={removeFoto}
                                            style={{ float: 'left', marginLeft: '0.5rem', backgroundColor: 'red' }}>
                                            <Delete />
                                        </Button>)}
                                    {botaoConfirmaClicado === false && editavel &&
                                        (
                                            <Button
                                                variant="contained"
                                                onClick={handleConfirmPhoto}
                                                style={{ float: 'right', marginRight: '0.5rem', backgroundColor: 'green' }}
                                            >
                                                <Check />
                                            </Button>
                                        )
                                    }

                                </div>
                            </ImagePreview>
                        </>
                    ) : (
                        <ImagePreview>
                            <span style={{ display: 'inline-block', width: '10rem', height: '12rem', textAlign: 'center', lineHeight: '12rem' }}>
                                Pré-visualização
                            </span>
                        </ImagePreview>
                    )}
                </div>

            </div>
        </div>
    );
};

export default DetailPhotoInfo;
