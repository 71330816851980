export const valueOrEmptyString = (value) => {
    return value ? value : "";
};
export const valueOrEmptyDate = (value) => {
    return value ? new Date(value).toISOString().split("T")[0] : "";
};
export const valueOrFalse = (value) => {
    return value ? value : false;
};
export const emptyCodeAndLabel = {code: null, label: ""};
export const listWithEmptyCodeAndLabel = (lst) => { 
    return lst ? [emptyCodeAndLabel].concat(lst) : [emptyCodeAndLabel]; 
}

/* *********************************************************** */
/* funcao que define se o status eh ativo                    */
/* *********************************************************** */
export const isStatusActive = (status) => {
    return status.label.toUpperCase() !== "Desligado".toUpperCase() && status.label.toUpperCase() !== "Cancelado".toUpperCase()
}

export const sortByLabelFunction = (a,b) => {
    if(a.label < b.label) { return -1; }
    if(a.label > b.label) { return 1; }
    return 0;
}