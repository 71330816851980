export class Colaborador {
  constructor(obj) {
    if (obj) {
      this.CodColaborador = obj.codColaborador;
      this.NomeColaborador = obj.nomeColaborador;
      this.CPFColaborador = obj.cpfColaborador;
      this.DataNascimento = obj.dataNascimento;
      this.Sexo = obj.sexo;
      this.Escolaridade = obj.escolaridade;
      this.EstadoCivil = obj.estadoCivil;
      this.Naturalidade = obj.naturalidade;
      this.Nacionalidade = obj.nacionalidade;
      this.Matricula = obj.matricula;
      this.Status = obj.status;
      this.DataAdmissao = obj.dataAdmissao;
      this.DataDemissao = obj.dataDemissao;
      this.Filial = obj.filial;
      this.Funcao = obj.funcao;
      this.Nivel = obj.nivel;
      this.RegimeContratual = obj.regimeContratual;
      this.ValeTransporte = obj.valeTransporte;
      this.ValeRefeicao = obj.valeRefeicao;
      this.ValeAlimentacao = obj.valeAlimentacao;
      this.AjudaCusto = obj.ajudaCusto;
      this.Departamento = obj.departamento;
      this.Gestor = obj.gestor;
      this.Posto = obj.posto;

      this.NomePai = obj.nomePai;
      this.NomeMae = obj.nomeMae;
      this.Endereco = obj.endereco;
      this.Numero = obj.numero;
      this.Complemento = obj.complemento;
      this.Bairro = obj.bairro;
      this.Cidade = obj.cidade;
      this.Estado = obj.estado;
      this.CEP = obj.cep;
      this.TelefoneFixo = obj.telefoneFixo;
      this.TelefoneCelular = obj.telefoneCelular;
      this.TelefoneAlternativo = obj.telefoneAlternativo;

      this.Esocial = obj.esocial;
      this.RGNumero = obj.rgNumero;
      this.RGOrgaoEmissor = obj.rgOrgaoEmissor;
      this.RGDataEmissao = obj.rgDataEmissao;
      this.CNHTipo = obj.cnhTipo;
      this.CNHNumero = obj.cnhNumero;
      this.CNHDataVencimento = obj.cnhDataVencimento;
      this.CNHDataEmissao = obj.cnhDataEmissao;
      this.CTPSNumero = obj.ctpsNumero;
      this.CTPSSerie = obj.ctpsSerie;
      this.CTPSDataEmissao = obj.ctpsDataEmissao;
      this.CTPSUF = obj.ctpsuf;
      this.TituloENumero = obj.tituloENumero;
      this.TituloEZona = obj.tituloEZona;
      this.TituloESecao = obj.tituloESecao;
      this.TituloEDataEmissao = obj.tituloEDataEmissao;
      this.CertificadoReservista = obj.certificadoReservista;
      this.PISPASEP = obj.pispasep;
      this.Email = obj.email;
      this.EmailPessoal = obj.emailPessoal;
      this.FotoColaborador = obj.fotoColaborador;
      this.DataNascimentoFilhos = obj.dataNascimentoFilhos;
    } else {
      this.CodColaborador = null;
      this.NomeColaborador = null;
      this.CPFColaborador = null;
      this.DataNascimento = null;
      this.Sexo = null;
      this.Escolaridade = null;
      this.EstadoCivil = null;
      this.Naturalidade = null;
      this.Nacionalidade = null;
      this.Status = null;
      this.DataAdmissao = null;
      this.DataDemissao = null;
      this.Filial = null;
      this.Funcao = null;
      this.Nivel = null;
      this.RegimeContratual = null;
      this.ValeTransporte = null;
      this.ValeRefeicao = null;
      this.ValeAlimentacao = null;
      this.AjudaCusto = null;
      this.Departamento = null;
      this.Gestor = null;
      this.Posto = null;

      this.NomePai = null;
      this.NomeMae = null;
      this.Endereco = null;
      this.Numero = null;
      this.Complemento = null;
      this.Bairro = null;
      this.Cidade = null;
      this.Estado = null;
      this.CEP = null;
      this.TelefoneFixo = null;
      this.TelefoneCelular = null;
      this.TelefoneAlternativo = null;

      this.Esocial = null;
      this.RGNumero = null;
      this.RGOrgaoEmissor = null;
      this.RGDataEmissao = null;
      this.CNHTipo = null;
      this.CNHNumero = null;
      this.CNHDataVencimento = null;
      this.CNHDataEmissao = null;
      this.CTPSNumero = null;
      this.CTPSSerie = null;
      this.CTPSDataEmissao = null;
      this.CTPSUF = null;
      this.TituloENumero = null;
      this.TituloEZona = null;
      this.TituloESecao = null;
      this.TituloEDataEmissao = null;
      this.CertificadoReservista = null;
      this.PISPASEP = null;
      this.Email = null;
      this.EmailPessoal = null;
      this.DataNascimentoFilhos = null;
      this.FotoColaborador = null;
    }
  }
}

export class CodeAndLabel {
  constructor(obj) {
    if (obj) {
      this.code = obj.code;
      this.label = obj.label;
    } else {
      this.code = null;
      this.label = null;
    }
  }
}