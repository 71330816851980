import React from "react";
import { TextField } from "@mui/material";
import { valueOrEmptyDate } from "../app/Tools";

const DetailChildrenInfo = ({
    filhos,
    handleFilhos,
    editavel,
}) => {
    const resizeFilhos = (event) => {
        const newLength = Number(event.target.value);

        if (newLength < 0) {
            // do nothing
        } else if (newLength > filhos.length) {
            const temp = [
                ...filhos,
                ...new Array(newLength - filhos.length).fill(
                    null
                ),
            ];
            handleFilhos(temp);
        } else {
            const temp = filhos.slice(0, newLength);
            handleFilhos(temp);
        }
    };

    return (
        <div
            style={{
                backgroundColor: "#EDEDED",
                border: 0,
                borderRadius: 9,
                padding: 10,
                margin: 5,
            }}
        >

            <div
                style={{
                    backgroundColor: "white",
                    border: 0,
                    borderRadius: 9,
                    padding: 10,
                }}>
                <TextField
                    id="qtdFilhos"
                    required={true}
                    label="Quantidade de Filhos"
                    value={filhos.length}
                    onChange={resizeFilhos}
                    type="number"
                    InputProps={{
                        disabled: !editavel,
                        inputProps: {
                            min: 0
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                />
                {filhos.map((e, i) => {
                    return (
                        <TextField
                            id={`DataNascimentoFilho${i}`}
                            key={`DataNascimentoFilho${i}`}   // key eh necessario aqui para evitar erro em runtine: warning each child in a list should have a unique key prop
                            label={`Data de Nascimento Filho ${i + 1}`}
                            required={true}
                            type="date"
                            value={valueOrEmptyDate(e)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                group: "filhosColaborador",
                            }}
                            onChange={(e, newValue) => {
                                const newData = [...filhos];
                                newData[i] = e.target.value;
                                handleFilhos(newData);
                            }}
                            variant="outlined"
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default DetailChildrenInfo;