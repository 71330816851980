import React, { useContext } from "react";
import {
    CssBaseline,
    Box,
    Typography,
    Card,
    CardActionArea,
    Grid
} from "@mui/material";

import logo from "./images/logo.png";
import { Settings, BadgeOutlined, BarChart, Groups, AccountTreeTwoTone, CalendarMonth } from "@mui/icons-material";
// import { getApiVagaPermission } from "./api/VagaPermission-api";
import { PattrolUserContext } from "pattrol-frontend-components";

const Home = () => {
    const { permissions } = useContext(PattrolUserContext);

    const hasRequiredPermissions = (requiredPermissions) => {
        return requiredPermissions.some(permission => permissions.includes(permission));
    };

    const iconStyle = { fontSize: 50 };
    const links = [
        {
            title: "Cadastro de Funcionários",
            url: "/hr/colaborador",
            icon: <Groups style={iconStyle} />,
            permissionsRequired: [1, 2, 3]
        },
        {
            title: "Organograma",
            url: "/organograma",
            icon: <AccountTreeTwoTone style={iconStyle} />,
            permissionsRequired: [1, 2, 3, 4]
        },
        {
            title: "Telemetria",
            url: "/telemetria",
            icon: <BarChart style={iconStyle} />,
            permissionsRequired: [5],
        },
        {
            title: "Solicitação de Vagas",
            url: "hr/vaga",
            icon: <BadgeOutlined style={iconStyle} />,
            permissionsRequired: [1]
        },
        {
            title: "Aviso de Folga",
            url: "/avisofolga" ,
            icon: <CalendarMonth style={iconStyle} />,
            permissionsRequired: [1, 2, 3,7]
        },
        {
            title: "Manutenção",
            url: "/mecanica",
            icon: <Settings style={iconStyle} />,
            permissionsRequired: [1, 6]
        }
       
    ];

    const gradientBackground = {
        background: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    };

    const cardStyle = {
        width: 290,
        height: 130,
        marginBottom: 2,
        fontSize: "1rem",
        backgroundColor: "#568564", // Deep green, earthy yet modern
        fontWeight: "bold",
        color: "#d1e6d6", // White for contrast
        borderRadius: "3", // Square edges for a modern look
        transition: "all 0.3s ease-in-out", // Smooth transition for hover effect
        "&:hover": {
            backgroundColor: "#366b43", // Slightly darker shade on hover
            transform: "translateY(-2px)", // Slight lift effect on hover
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Adding a shadow for depth
            color: "white",
        },
        display: "flex", // Added for centering
        flexDirection: "column", // Stack items vertically
        justifyContent: "center", // Center vertically
        alignItems: "center", // Center horizontally
    };

    return (
        <>
            <CssBaseline />
            <Box sx={gradientBackground}>
                <a href='/site'>
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            width: "auto",
                            height: "10rem",
                        }}
                    />
                </a>
                <Grid container spacing={2} sx={{ maxWidth: 1100, marginLeft: '55px', justifyContent: 'center' }}>
                    {links
                        .filter(link => hasRequiredPermissions(link.permissionsRequired))
                        .map((link, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <CardActionArea href={link.url}>
                                    <Card sx={cardStyle}>
                                        {link.icon}
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            {link.title}
                                        </Typography>
                                    </Card>
                                </CardActionArea>
                            </Grid>
                        ))}
                </Grid>
            </Box>
        </>
    );
};

export default Home;
