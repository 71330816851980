import React, { useState, useEffect } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomAlert = (props) => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  },[props.open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    
    props.onClose();
    setOpen(false);
  };

  return (
      <Snackbar open={open} 
        autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.severity? props.severity:"info"} sx={{ width: "100%" }}>
          {props.children}
        </Alert>
      </Snackbar>
  );
};

export default CustomAlert;