import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { DataProvider } from "./app/DataContext";
import MainFeed from "./features/MainFeed";
import Details from "./features/Details";
import Home from "./Home";
import { PattrolLayout } from "pattrol-frontend-components";
import { Box } from '@material-ui/core';

import './styles.css'

function App() {

    return (
        <PattrolLayout
            permissionsAllowed={[1, 2, 3, 4, 5, 6]}>
            <ScopedCssBaseline>
                <DataProvider>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route
                                path="/hr/colaborador/details"
                                component={Details}
                            />
                            <Route path="/hr/colaborador" >
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <MainFeed />
                                    </Box>
                                </Box>
                            </Route>
                        </Switch>
                    </Router>
                </DataProvider>
            </ScopedCssBaseline>
        </PattrolLayout>
    );
}

export default App;
