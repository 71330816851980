import React, { useEffect, useState, useContext } from "react";
import { LinearProgress, Button, Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
    DataGrid,
    GridToolbar,
    GridFooterContainer,
    ptBR,
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";
import MuiPagination from '@mui/material/Pagination';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IconButton from '@mui/material/IconButton';
import { ptBR as coreptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";


import {
    listWithEmptyCodeAndLabel,
    emptyCodeAndLabel,
    isStatusActive,
    sortByLabelFunction,
} from "../app/Tools";
import { Colaborador } from "../app/Colaborador";
import { DataContext } from "../app/DataContext";

import CustomAlert from "./CustomAlert";
import { PattrolUserContext } from "pattrol-frontend-components";
import { Visibility } from "@mui/icons-material";


const theme = createTheme(
    {
        palette: {
            neutral: {
                main: "#000",
                contrastText: "#000",
            },
            white: {
                main: "#FFFFFF",
            },
            primary: {
                main: "#2e5534",
            },
            secondary: {
                main: "#8c1b1f",
            },

        },
    },
    ptBR,
    coreptBR,
);




const useStyles = makeStyles({
    menu: {
        border: "1px transparent",
    },
    menuA: {
        backgroundColor: "blue",
    },
    tituloRegister: {
        fontSize: "20px",
        fontStyle: "initial",
        fontFamily: "Verdana",
        margin: "1% auto",
        marginBottom: "2%",
        textAlign: "center",
        color: "rgb(0, 48, 7)",
    },
    divButton: {
        backgroundColor: "blue",
        margin: "30px",
    },
});

/* ======================================================== */
/* componente principal                                     */
/* ======================================================== */
const MainFeed = () => {
    const dataContext = useContext(DataContext);
    const classes = useStyles();
    const [colaboradores, setColaboradores] = useState([]);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [loadData, setLoadData] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [hideInactive, setHideInactive] = useState(true);
    const { permissions } = useContext(PattrolUserContext);
    const [statusList, setStatusList] = useState([]);
    const permissionsEdit = permissions ? permissions.some((perm) => [1, 2].includes(perm)) : false; // permisaao para editar

    /* *********************************************************** */
    /*  definicao de colunas do grid                               */
    /* *********************************************************** */
    const columns = [
        {
            field: "nome",
            headerName: "Nome",
            flex: 1.5,
        },
        {
            field: "filial",
            headerName: "Filial",
            flex: 1,
            type: "singleSelect",
            hide: false,
            valueGetter: (params) => {
                return params.row.filial
                    ? {
                        value: params.row.filial.code
                            ? params.row.filial.code
                            : 0,
                        code: params.row.filial.code
                            ? params.row.filial.code
                            : 0,
                        label: params.row.filial.label
                            ? params.row.filial.label
                            : 0,
                    }
                    : { value: 0, code: 0, label: "" };
            },
            renderCell: (params) => {
                return params.row.filial ? (
                    <p>{params.row.filial.label}</p>
                ) : (
                    <p />
                );
            },
            valueOptions: [{ code: 0, label: "(nulo)" }]
                .concat(dataContext.tabFilial ? dataContext.tabFilial : [])
                .map((s) => {
                    return { value: s.code, code: s.code, label: s.label };
                }),
            sortComparator: sortByLabelFunction,
            renderFilterList: (props) => { },
        },
        {
            field: "funcao",
            headerName: "Função",
            flex: 1,
            type: "singleSelect",
            hide: false,
            valueGetter: (params) => {
                return params.row.funcao
                    ? {
                        value: params.row.funcao.code
                            ? params.row.funcao.code
                            : 0,
                        code: params.row.funcao.code
                            ? params.row.funcao.code
                            : 0,
                        label: params.row.funcao.label
                            ? params.row.funcao.label
                            : 0,
                    }
                    : { value: 0, code: 0, label: "" };
            },
            renderCell: (params) => {
                return params.row.funcao ? (
                    <p>{params.row.funcao.label}</p>
                ) : (
                    <p />
                );
            },
            valueOptions: [{ code: 0, label: "(nulo)" }]
                .concat(dataContext.tabFuncao ? dataContext.tabFuncao : [])
                .map((s) => {
                    return { value: s.code, code: s.code, label: s.label };
                }),
            sortComparator: sortByLabelFunction,
        },
        {
            field: "nivel",
            headerName: "Nível",
            flex: 1,
            type: "singleSelect",
            hide: false,
            valueGetter: (params) => {
                return params.row.nivel
                    ? {
                        value: params.row.nivel.code
                            ? params.row.nivel.code
                            : 0,
                        code: params.row.nivel.code
                            ? params.row.nivel.code
                            : 0,
                        label: params.row.nivel.label
                            ? params.row.nivel.label
                            : 0,
                    }
                    : { value: 0, code: 0, label: "" };
            },
            renderCell: (params) => {
                return params.row.nivel ? (
                    <p>{params.row.nivel.label}</p>
                ) : (
                    <p />
                );
            },
            valueOptions: [{ code: 0, label: "(nulo)" }]
                .concat(dataContext.tabNivel ? dataContext.tabNivel : [])
                .map((s) => {
                    return { value: s.code, code: s.code, label: s.label };
                }),
            sortComparator: sortByLabelFunction,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            type: "singleSelect",
            hide: false,
            valueGetter: (params) => {
                return params.row.status
                    ? {
                        value: params.row.status.code
                            ? params.row.status.code
                            : 0,
                        code: params.row.status.code
                            ? params.row.status.code
                            : 0,
                        label: params.row.status.label
                            ? params.row.status.label
                            : 0,
                    }
                    : { value: 0, code: 0, label: "" };
            },
            renderCell: (params) => {
                return params.row.status ? (
                    <p>{params.row.status.label}</p>
                ) : (
                    <p />
                );
            },
            valueOptions: [{ code: 0, label: "(nulo)" }]
                .concat(statusList ? statusList : [])
                .map((s) => {
                    return { value: s.code, code: s.code, label: s.label };
                }),
            sortComparator: sortByLabelFunction,
        },
        {
            field: "dataAdmissao",
            headerName: "Data Admissão",
            flex: 1,
            type: "date",
            hide: false,
            textAlign: "center",
            renderCell: (params) => {
                const data = new Date(params.row.dataAdmissao);
                return params.row.dataAdmissao ? (
                    <p>{data.toLocaleDateString()}</p>
                ) : (
                    <p />
                );
            },
        },
        {
            field: "editar",
            headerName: permissionsEdit ? "Editar" : "Detalhes",
            flex: 0.5,
            filterable: false,
            sortable: false,
            disableExport: true,
            renderCell: (params) => {
                const data = params.row.codColaborador;
                return (
                    <strong>
                        <Link to={{ pathname: "/hr/colaborador/details", data }}>
                            <Button startIcon={(permissionsEdit ? <EditIcon /> : <Visibility />)}></Button>
                        </Link>
                    </strong>
                );
            }
        }
    ];
    /* *********************************************************** */
    /*  lista de status varia com opção mostrar/esconder inativos  */
    /* *********************************************************** */
    // lista de status
    useEffect(() => {
        // recupera a lista do banco de dados
        let lst = dataContext.tabStatus;

        // verifica se deve excluir status que correspodem a inativos
        if (hideInactive) {
            lst = lst.filter(function filterInactive(item) {
                return isStatusActive(item);
            });
        }
        setStatusList(lst);
    }, [dataContext.tabStatus, hideInactive]);

    /* *********************************************************** */
    /*  Carrega lista de colaboradores do Banco de Dados, via API  */
    /* *********************************************************** */
    useEffect(() => {
        if (loadData) {
            // muda o indicador para que nao carregue novamente enquanto esta pagina existir
            setLoadData(false);

            // apresenta ao usuario indicador de carga em andamento
            setIsLoading(true);

            // busca lista de colaboradores
            dataContext.getApiColaboradorList().then((response) => {
                let colabs = [];

                if (response) {
                    colabs = response.map((e) => {
                        return new Colaborador({ ...e });
                    });
                } else {
                    setOpen(true);
                }
                setIsLoading(false);
                setColaboradores([...colabs]);
            });
        }
    }, [dataContext, loadData]);

    /* *********************************************************** */
    /* muda a lista de colaboradores a apresentar, toda vez que:   */
    /* - mudar a lista de todos os colaboradores                   */
    /* - mudar a opcao "ocultar inativos"                          */
    /* *********************************************************** */
    useEffect(() => {
        // mapeia os itens a apresentar a partir da lista de todos os itens
        const newRows = colaboradores
            .map((e) => {
                const thisRow = {
                    codColaborador: e.CodColaborador,
                    nome: e.NomeColaborador,
                    filial: e.Filial ? e.Filial : emptyCodeAndLabel,
                    funcao: e.Funcao ? e.Funcao : emptyCodeAndLabel,
                    nivel: e.Nivel ? e.Nivel : emptyCodeAndLabel,
                    status: e.Status ? e.Status : emptyCodeAndLabel,
                    dataAdmissao: e.DataAdmissao,
                };
                return { ...thisRow };
            })
            .filter((row) =>
                listWithEmptyCodeAndLabel(statusList)
                    .map((s) => s.code)
                    .includes(row.status.code)
            );
        setRows([...newRows]);
    }, [colaboradores, statusList]);

    /* *********************************************************** */
    /* footer customizado, inclui o botão para adicionar novo item */
    /* *********************************************************** */
    function Pagination({ page, onPageChange, className }) {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    function CustomPagination(props) {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }


    function CustomGridFooter() {

        return (
            <GridFooterContainer>
                {permissionsEdit && (
                    <Link to={{ pathname: "/hr/colaborador/details" }}>
                        <IconButton
                            size="small"
                            style={{
                                margin: '10px',
                                borderRadius: '5px ',
                                backgroundColor: "#757575",
                            }}
                        >
                            <GroupAddIcon style={{ fontSize: '22px', marginRight: '10px', color: 'white' }} />
                            <span style={{ fontSize: '16px', color: 'white' }}>Adicionar Colaborador</span>
                        </IconButton>
                    </Link>
                )}
                <FormControlLabel
                    label="Ocultar inativos"
                    control={
                        <Switch
                            checked={hideInactive}
                            onChange={() => {
                                setHideInactive(!hideInactive);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                    style={{ padding: 15 }}
                />


                <CustomPagination />
            </GridFooterContainer>
        );
    }




    /* *********************************************************** */
    /* apresenta o grid principal                                  */
    /* *********************************************************** */
    return (
        <div
            style={{
                padding: "0px 50px",
                marginTop: '80px',
                marginLeft: '55px'
            }}
        >
            <h2 className={classes.tituloRegister}>
                Cadastro de Colaboradores
            </h2>
            <ThemeProvider theme={theme}>
                <div
                    style={{
                        height: "calc(100vh - 140px)",
                        width: "100vw - 110px",
                        borderRadius: "10px",
                        marginBottom: "10px",
                        padding: "5px",
                        backgroundColor: "#EDEDED",
                        border: '3px solid #D1D1D1',
                    }}
                >
                    {isLoading && <LinearProgress />}

                    <DataGrid
                        getRowId={(row) => row.codColaborador}
                        className={classes.menu}
                        columns={columns}
                        rows={rows}
                        components={{
                            Toolbar: GridToolbar,
                            Footer: CustomGridFooter,
                        }}
                        componentsProps={{
                            row: {
                                style: { cursor: "context-menu" },
                            },
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </div>
            </ThemeProvider>
            <CustomAlert
                open={open}
                severity="error"
                onClose={() => setOpen(false)}
            >
                Erro ao carregar os dados!
            </CustomAlert>
        </div>
    );
};

export default MainFeed;
