import * as React from 'react';
import { ArrowBack, ArrowForward, Diversity2, Edit, Delete, FolderShared, Person, PersonAdd, Print } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';
import {
    Grid,
    Stepper,
    Switch,
    Button,
    Step,
    StepButton,
    StepLabel,
    Box,
    FormControlLabel,
} from "@mui/material";

const steps = ['Informações\nPessoais', 'Informações\nComplementares', 'Informações\nCadastrais', 'Documentos\nPessoais'];
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '35px',
        marginBottom: '50px'
    },
    containerStepper: {
        width: "60vw",
        height: "20vh",
    },
}
const CustomStepLabel = styled(StepLabel)({
    '.MuiStepLabel-label': {
        whiteSpace: 'pre-line', // Permite quebra de linha no label
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});
//estilo do ícone
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 150deg, rgb(140,27,30) 0%, rgb(46,85,52) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 150deg, rgb(140,27,30) 0%, rgb(46,85,52) 100%)',
    }),
}));

//componente do icone
function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <Person />,
        2: <PersonAdd />,
        3: <Diversity2 />,
        4: <FolderShared />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {active ? <Edit /> : (icons[String(props.icon)])}
        </ColorlibStepIconRoot >
    );
}


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    marginBottom: '60px',
}));

export default function StepperComponent({
    renderStepContent,
    isNew,
    setEditavel,
    editavel,
    saveData,
    setPromptDelete,
    imprimeFichaRegistro,
    permissionsEdit }) {

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const lastStep = activeStep === 3;

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleNext = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    return (
        <div style={styles.container}>
            <div style={styles.containerStepper}>
                <Stepper nonLinear activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton onClick={handleStep(index)}>
                                <CustomStepLabel StepIconComponent={ColorlibStepIcon} >
                                    {label}
                                </CustomStepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div>
                {permissionsEdit && (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isNew ? null :
                            <div style={{ left: 0 }}>
                                <FormControlLabel label="Habilitar Edição" control={
                                    <Switch
                                        checked={editavel}
                                        onChange={() => {
                                            setEditavel(!editavel);
                                        }}
                                    />
                                } />
                            </div>
                        }
                    </Box>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        <ArrowBack style={{ fontSize: '18px' }} />
                        <span style={{ marginLeft: '10px' }}>
                            VOLTAR
                        </span>
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {lastStep ? (
                        <Button onClick={saveData} sx={{ mr: 1, color: 'green' }} disabled={!editavel}>
                            <span style={{ marginRight: '10px' }}>
                                {isNew ? 'Confirmar Inclusão' : 'Salvar Alterações'}
                            </span>
                            <ArrowForward style={{ fontSize: '18px' }} />
                        </Button>
                    ) :
                        <Button onClick={handleNext} sx={{ mr: 1, color: 'green' }}>
                            <span style={{ marginRight: '10px' }}>
                                PRÓXIMO
                            </span>
                            <ArrowForward style={{ fontSize: '18px' }} />
                        </Button>
                    }
                </Box>
                {renderStepContent(activeStep)}
                {permissionsEdit && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                        {isNew ? null :
                            <Button variant="outlined" startIcon={<Print />} disabled={editavel} onClick={imprimeFichaRegistro}>Imprimir Ficha</Button>
                        }
                        <Box sx={{ flex: 'center', marginLeft: '10px', marginRight: '10px' }} />
                        {isNew ? null :
                            <Grid item md='auto'>
                                <Button variant="outlined" color="error" endIcon={<Delete />} disabled={!editavel}
                                    onClick={() => {
                                        setPromptDelete(true);
                                    }}
                                >
                                    Excluir Registro
                                </Button>
                            </Grid>
                        }
                    </Box>
                )}
            </div>
        </div>
    );
}
