import React, { useContext } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { valueOrEmptyString, valueOrEmptyDate, listWithEmptyCodeAndLabel } from "../app/Tools";

import { DataContext } from "../app/DataContext";

const DetailBaseInfo = ({
    colaborador,
    handleChangeText,
    setColaborador,
    editavel,
}) => {
    const filterNaturalidade = createFilterOptions({ stringify: (option) => option.label });
    const filterNacionalidade = createFilterOptions({ stringify: (option) => option.label });
    const dataContext = useContext(DataContext);
    return (
        <div
            style={{
                backgroundColor: "#D1D1D1",
                border: 0,
                borderRadius: 9,
                padding: 10,
                margin: 5,
            }}
        >
            <div
                style={{
                    backgroundColor: "white",
                    border: 0,
                    borderRadius: 9,
                    padding: 10,
                }}
            >
                <Grid container spacing={2} rowSpacing={2}>
                    {/* Linha: nome, cpf */}
                    <Grid item md={9}>
                        <TextField
                            id="NomeColaborador"
                            label="Nome"
                            required={true}
                            value={valueOrEmptyString(colaborador.NomeColaborador)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChangeText}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="CPFColaborador"
                            label="CPF"
                            value={valueOrEmptyString(colaborador.CPFColaborador)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChangeText}
                            variant="outlined"
                        />
                    </Grid>

                    {/* linha: Nascimento, Sexo, Escolaridade, Estado Civil */}
                    <Grid item md={3}>
                        <TextField
                            id="DataNascimento"
                            label="Data Nascimento"
                            type="date"
                            value={valueOrEmptyDate(colaborador.DataNascimento)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChangeText}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            id="Sexo"
                            value={colaborador.Sexo}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Sexo: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabSexo)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Sexo"
                                    InputLabelProps={{ shrink: true }}
                                />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            id="Escolaridade"
                            value={colaborador.Escolaridade}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Escolaridade: newValue
                                });
                            }}
                            disabled={!editavel}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabEscolaridade)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Escolaridade"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            id="EstadoCivil"
                            value={colaborador.EstadoCivil}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, EstadoCivil: newValue
                                });
                            }}
                            disabled={!editavel}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabEstadoCivil)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Estado Civil"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>

                    {/*  Naturalidade, Nacionalidade */}
                    <Grid item md={6}>
                        <Autocomplete
                            value={colaborador.Naturalidade}
                            onChange={(event, newValue) => {
                                let newValueObj = { code: null, label: "" };;
                                if (typeof newValue === 'string') {
                                    newValueObj.label = newValue;
                                } else if (!newValue) {
                                    // do nothing
                                } else if (newValue.inputValue) {
                                    // Create a new value from the user input
                                    newValueObj.label = newValue.inputValue;
                                } else {
                                    newValueObj.code = newValue.code;
                                    newValueObj.label = newValue.label;
                                }
                                setColaborador({
                                    ...colaborador, Naturalidade: newValueObj
                                });
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filterNaturalidade(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.label);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        label: `Adicionar "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="Naturalidade"
                            disabled={!editavel}
                            options={listWithEmptyCodeAndLabel(dataContext.tabCidade)}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            // sx={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Naturalidade" InputLabelProps={{ shrink: true }} />
                            )}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            value={colaborador.Nacionalidade}
                            onChange={(event, newValue) => {
                                let newValueObj = { code: null, label: "" };;
                                if (typeof newValue === 'string') {
                                    newValueObj.label = newValue;
                                } else if (!newValue) {
                                    // do nothing
                                } else if (newValue.inputValue) {
                                    // Create a new value from the user input
                                    newValueObj.label = newValue.inputValue;
                                } else {
                                    newValueObj.code = newValue.code;
                                    newValueObj.label = newValue.label;
                                }
                                setColaborador({
                                    ...colaborador, Nacionalidade: newValueObj
                                });
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filterNacionalidade(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.label);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        label: `Adicionar "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="Nacionalidade"
                            disabled={!editavel}
                            options={listWithEmptyCodeAndLabel(dataContext.tabPais)}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            // sx={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Pais da Nacionalidade" InputLabelProps={{ shrink: true }} />
                            )}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="Email"
                            label="Email"
                            value={valueOrEmptyString(colaborador.Email)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChangeText}
                            variant="outlined"
                            placeholder="nome@pattrol.com.br"
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="EmailPessoal"
                            label="Email Pessoal"
                            value={valueOrEmptyString(colaborador.EmailPessoal)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChangeText}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default DetailBaseInfo;
