import React, { useContext } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { valueOrEmptyString, valueOrEmptyDate, listWithEmptyCodeAndLabel } from "../app/Tools";

import DetailRHInfoVales from "./DetailRHInfoVales";
import { DataContext } from "../app/DataContext";

const DetailRHInfo = ({
    colaborador,
    handleChange,
    setColaborador,
    editavel,
}) => {
    const dataContext = useContext(DataContext);

    return (
        <div
            style={{
                backgroundColor: "#EDEDED",
                border: 0,
                borderRadius: 9,
                padding: 10,
                margin: 5,
            }}
        >

            <div
                style={{
                    backgroundColor: "white",
                    border: 0,
                    borderRadius: 9,
                    padding: 10,
                }}
            >

                <Grid container spacing={2} rowSpacing={2}>
                    {/* Matricula, Status, Data Admissao, Data Demissao */}
                    <Grid item md={3}>
                        <TextField
                            id="Matricula"
                            label="Matrícula"
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={valueOrEmptyString(colaborador.Matricula)}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            id="Status"
                            value={colaborador.Status}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Status: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabStatus)}
                            disabled={!editavel}

                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Status"
                                    InputLabelProps={{ shrink: true }}
                                    required={true}
                                />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="DataAdmissao"
                            label="Admissão"
                            type="date"
                            value={valueOrEmptyDate(colaborador.DataAdmissao)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="DataDemissao"
                            label="Demissão"
                            type="date"
                            value={valueOrEmptyDate(colaborador.DataDemissao)}
                            InputProps={{
                                disabled: !editavel,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>

                    {/* Filial, Funcao, Nivel */}
                    <Grid item md={4}>
                        <Autocomplete
                            id="Filial"
                            value={colaborador.Filial}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Filial: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabFilial)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Filial"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            id="Funcao"
                            value={colaborador.Funcao}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Funcao: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabFuncao)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Função"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            id="Nivel"
                            value={colaborador.Nivel}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Nivel: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabNivel)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Nível"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>

                    {/* Regime, Vales */}
                    <Grid item md={4}>
                        <Autocomplete
                            id="RegimeContratual"
                            value={colaborador.RegimeContratual}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, RegimeContratual: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabRegime)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Regime"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>

                    <Grid item md={8}>
                        <DetailRHInfoVales
                            editavel={editavel}
                            colaborador={colaborador}
                            setColaborador={setColaborador}
                        />
                    </Grid>


                    {/* Departamento, Gestor, Posto de trabalho */}
                    <Grid item md={4}>
                        <Autocomplete
                            id="Departamento"
                            value={colaborador.Departamento}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Departamento: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabDepartamento)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Departamento"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            id="Gestor"
                            value={colaborador.Gestor}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Gestor: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabGestor)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Gestor"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            id="Posto"
                            value={colaborador.Posto}
                            onChange={(e, newValue) => {
                                setColaborador({
                                    ...colaborador, Posto: newValue
                                });
                            }}
                            isOptionEqualToValue={(option, value) => value && (option.code === value.code)}
                            options={listWithEmptyCodeAndLabel(dataContext.tabPosto)}
                            disabled={!editavel}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Posto de Trabalho"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default DetailRHInfo;